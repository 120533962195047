// import style css
import { Link, useParams } from "react-router-dom";
import style from "../styles/Category.module.scss";
import { useContext, useEffect, useState, useMemo, useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import PrCart from "../components/PrCart";
import ArrowIcon from "../assets/images/icons/ArrowIcon";
import { ApiGlobal } from "../Contexts/ApiGlobal";
import sirr from "../Helpers/Sirr";
import urls from "../ApiValues/urls";
import { useTranslation } from "react-i18next";
import FilterData from "../components/FilterData";


const scrollElement = (swiperRef, i) => {
    swiperRef.current.swiper.slideTo(i);
};

function Category() {
    const swiperRef = useRef(null);
    const {i18n}=useTranslation();
    const {t}=useTranslation();
    const {categoryNameDatas,sort} = useContext(ApiGlobal)
    const { slug } = useParams();
    const [active, setActive] = useState(slug);
    const [productData, setproductData]=useState([])
 
    const handleSelectedCategory = (PrCategory,slug) => {
        return PrCategory.find((item) => item.slug === slug) || {};
    };

    const selectedData = useMemo(
        () => handleSelectedCategory(categoryNameDatas, active),
        [JSON.stringify(categoryNameDatas), active]
    );

    const getProducts = async (category = null) => {
        try {
            const productsResponse = await sirr.api().get(`${urls.products(i18n.language,sort)}${category ? '&category='+category: ''}`)
            // const productsResponse = await sirr.api().get(`${urls.products}`)
            setproductData(productsResponse.data.data) 
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(()=>{
        getProducts(slug);   
    },[i18n.language,sort])


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section id={style.category}>
            <div className="container">
                <div className={style.categoryTitleWrapper}>
                    <Swiper
                        slidesPerView={"auto"}
                        spaceBetween={10}
                        pagination={{
                            clickable: true,
                        }}

						slideToClickedSlide={true}
                        onAfterInit={() => {
							categoryNameDatas.map((c, i) => c.slug === active ? scrollElement(swiperRef, i) : '')
                        }}
                        ref={swiperRef}
                        className={style.mySwiper}
                    >
                        {categoryNameDatas.map((category, i) => (
                            <SwiperSlide
                                className={style.customClassname}
                                key={category.id}
                            >
                                <Link
                                    to={`/category/${category.slug}`}
                                    onClick={() => {
                                        setActive(category.slug);
                                        getProducts(category.slug);
                                    }}
                                    className={
                                        active === category.slug
                                            ? "categoryName activeCategory "
                                            : "categoryName"
                                    }
                                >
                                    {category.title}
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <hr className={style.line} />
                <div className={style.WaySort}>
                <div className={style.siteWay}>
                    <Link to="/" className={style.homePage}>
                       {t("homePage")}
                    </Link>
                    <ArrowIcon className={style.arrowIcon} />
                    <span className={style.currentPage}>{selectedData.title}</span>
                </div>
                <FilterData/>
                </div>
                <h2 className={style.currentPageTitle}>{selectedData.title}</h2>
                <div className={style.prList}>
                    {
                      productData.length  ? productData.map(product=>(
                        <PrCart  key={product.id} data={product} />
                       )) : <div>{t("noCategory")}</div> 
                    }
                </div>
            </div>
        </section>
    );
}
export default Category;


  // useEffect(() => {
    //     if (!slug) setActive(categoryNameDatas?.[0]?.slug);
    // }, [JSON.stringify(categoryNameDatas), slug]);