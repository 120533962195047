// import style css
import style from "../styles/CategoryNameAndImage.module.scss"
import { Link } from "react-router-dom"
import { useContext } from "react"
import { ApiGlobal } from "../Contexts/ApiGlobal"
import sirr from "../Helpers/Sirr"

export default function CategoryNameAndImage() {
	const {categoryNameDatas} = useContext(ApiGlobal)
  return (
	<section id={style.CategoryNameAndImage}>
		<div   className="container">
	<div className={style.CategoryWrapper}>
		{
			categoryNameDatas.map(category=>(
				<Link to={`/category/${category.slug}`} key={category.id} className={style.category}>
				<div className={style.image}>
				<img src={`${sirr.baseUrlImage}${category.image}`} alt={category.title} loading="lazy" />
				<div className={style.overlay}></div>
				</div>
				<h2 className={style.categoryName}>{category.title}</h2>
				</Link>   
			))
		}       
	</div>
	</div>
	</section>
  )
}
