import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import {ApiProvider } from "./Contexts/ApiGlobal";
import SearchResult from "./pages/SearchResult";
import { SearchGlobal } from "./Contexts/Search";
import Category from "./pages/Category";  


function App() {
  return (
      <>
          <SearchGlobal>
              <ApiProvider>
                  <Header />
                  <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/search" element={<SearchResult />} />
                      <Route path="/category/:slug" element={<Category />} />
                  </Routes>
                  <Footer />
              </ApiProvider>
          </SearchGlobal>
      </>
  );
}

export default App;
