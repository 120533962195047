import React from 'react'

export default function ArrowIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="8"
      fill="none"
      viewBox="0 0 4 8"
	  {...props}
    >  
      <path
        fill="#171717"
        d="M3.906 3.75L.51.096A.287.287 0 00.301 0a.287.287 0 00-.21.095L.087.1a.32.32 0 00-.064.105.353.353 0 00.064.354l3.197 3.443L.087 7.44a.32.32 0 00-.064.105.353.353 0 00.064.355l.004.004A.287.287 0 00.3 8a.287.287 0 00.21-.095l3.395-3.656a.348.348 0 00.07-.113.383.383 0 00-.07-.385z"
        opacity="0.7"
      ></path>
    </svg>
  )       
}   
