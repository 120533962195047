import { createContext, useState,useEffect } from "react"
import sirr from "../Helpers/Sirr";
import urls from "../ApiValues/urls";
import { useTranslation } from "react-i18next";

export const ApiGlobal=createContext();    


export const ApiProvider=({children})=>{
	const [categoryNameDatas, setCategoryNameDatas]=useState([]);  
    const {i18n}=useTranslation()
	const [sort,setSort] = useState(localStorage.getItem('sort') || sirr.sort);
   

	const changeSort = (s)=>{
		setSort(s);
		localStorage.setItem("sort",s);    
	};
	     

	useEffect(()=>{
		const getCategoryData=async ()=>{
		   try {
			   const ResCategory= await sirr.api().get(urls.categoriesName(i18n.language))  
				 setCategoryNameDatas(ResCategory.data.data)
		   } catch (error) {
			   console.log(error);
		   }
		}
		getCategoryData()
	       },[i18n.language])


	
	return(
	<ApiGlobal.Provider value={{categoryNameDatas,changeSort,sort}}>
		{children}
	</ApiGlobal.Provider>  
	)
}
