import React from "react";

export default function Location(props) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="16"
        fill="none"
        viewBox="0 0 14 16"
        {...props}
      >
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.448 15.256a.771.771 0 01-.896 0C2.689 12.503-1.411 6.84 2.733 2.746A6.074 6.074 0 017 1c1.6 0 3.135.628 4.267 1.745 4.144 4.093.044 9.756-3.819 12.511z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7 8.2A1.6 1.6 0 107 5a1.6 1.6 0 000 3.2z"
        ></path>
      </svg>
    );
}
