import { createContext, useState, useContext } from "react"
import sirr from "../Helpers/Sirr";
import urls from "../ApiValues/urls";
import { useTranslation } from "react-i18next";


const SearchContext=createContext();

export const SearchGlobal=({children})=>{
	const [searchTerm, setSearchTerm] = useState('');
	const [emptyResult, setEmptyResult] = useState(false);
	const [searchResult, setSearchResult] = useState([]);
	const {i18n}=useTranslation()
  
	const handleSearch = async (s=null) => {
	  try {
		if(!s) return;
		const response = await sirr.api().get(`${urls.search(i18n.language)}${s}`)
		let result = response.data.data;
		if(!result.length) setEmptyResult(true)
		setSearchResult(result);
	  } catch (error) {
		console.error(error);
	  }
	};
	  
	return(
		<SearchContext.Provider value={{
			searchTerm,
			setSearchTerm,
			searchResult,
			emptyResult,
			handleSearch,
		}}>
			{children}
		</SearchContext.Provider>
	)
}

export const useSearch = () => {
	const context = useContext(SearchContext);
  
	if (!context) {
	  throw new Error('useSearch must be used within a SearchProvider');
	}
	return context;
  };