// import style css
import style from "../styles/Fotter.module.scss";
import looptechLogo from "../assets/logo/looptech-logo-animated.svg";
import { useTranslation } from "react-i18next";
import sirr from "../Helpers/Sirr";

export default function Footer() {
    const { t } = useTranslation();

    return (
        <section id={style.footer}>
            <a href="/" className={style.footerLogo}>
                <img src={`${sirr.baseUrlImage}/settings/site-footer-logo.png`} alt="burada footer logo var" />
            </a>
            <a className={style.looptech} target="_blank" href="https://www.looptech.az/">
                <span className={style.prepared}>Created by :</span>
                <object className={style.looptechLogo} data={looptechLogo} alt="" />
            </a>
        </section>
    );
}
