import React from "react";

export default function CallIcon(props) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        fill="none"
        viewBox="0 0 13 13"
        {...props} 
      >
        <path
          fill="#fff"
          d="M12.374 8.74L9.312 7.368l-.008-.004a1.04 1.04 0 00-1.035.127L6.687 8.84c-1.003-.487-2.038-1.514-2.524-2.503l1.35-1.606a1.04 1.04 0 00.123-1.029v-.008L4.26.626A1.04 1.04 0 003.18.008 3.657 3.657 0 000 3.64C0 8.8 4.199 13 9.36 13a3.657 3.657 0 003.632-3.18 1.04 1.04 0 00-.618-1.08zM9.36 11.96a8.33 8.33 0 01-8.32-8.32 2.613 2.613 0 012.267-2.6.04.04 0 000 .008l1.365 3.055L3.328 5.71a1.04 1.04 0 00-.102 1.07c.589 1.205 1.802 2.41 3.02 2.998a1.04 1.04 0 001.072-.11l1.58-1.349 3.055 1.368h.007a2.614 2.614 0 01-2.6 2.272z"
        ></path>
      </svg>
    );
}
