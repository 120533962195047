import CategoryNameAndImage from "../components/CategoryNameAndImage";

export default function Home() {  

  return (   
	<>
	<CategoryNameAndImage/>
	</>
  )
}   
