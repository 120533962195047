import React, { useContext } from 'react'
import { ApiGlobal } from '../Contexts/ApiGlobal';
import { useTranslation } from 'react-i18next';
// import style 
import style from "../styles/FilterData.module.scss"
import { Select } from '@chakra-ui/react'


export default function FilterData() {
	const {changeSort,sort} = useContext(ApiGlobal);
	const  {t} = useTranslation();

    const onChange = (e)=>{
		const sort = e.target.value;
		changeSort(sort);   
	};  

  return (
	<div >
  <Select  value={sort} onChange={onChange}
            className={style.sort}
            w="165px" h="30"    
			focusBorderColor="transparent"
			>
         <option value="asc" >{t("CheapExpensive")}</option>
	     <option value="desc">{t("expensiveCheap")}</option>
		 <option value="az">{t("A-Z")}</option>
		 <option value="za">{t("Z-A")}</option>
              </Select>   
	
	</div>
  )    
}
