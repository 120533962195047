import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./i18n"
import App from './App';
import "./resetAllCss/style.css"
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <ChakraProvider>
       <App />
  </ChakraProvider>
  </BrowserRouter>      
);


