import React from "react";

export default function TimeIcon(props) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        {...props}
      >
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8 15A7 7 0 108 1a7 7 0 000 14z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.222 4.889v3.889h3.89"
        ></path>
      </svg>
    );
}
